<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-90 center mt4">
                <div class="pad16">
                    <p class="pb2 f3 b">
                        Report
                        <strong
                            ><font-awesome-icon
                                icon="fa-solid fa-question-circle fa-2xs"
                                class="text-muted clickModalIcon"
                                @click="clickModalReports = true"
                                style="cursor: pointer; padding-bottom: 2px"
                        /></strong>
                    </p>
                    <div class="dashboard-inquiry-modal" v-if="clickModalReports">
                        You can generate a report for the performance of your business on a daily, weekly or monthly basis. Click
                        <a href="#" target="_blank" style="color: blue">here</a> to learn more.
                    </div>
                </div>
                <div class="reportContainer">
                    <div class="flex justify-between" @click="toggleShowInventory">
                        <div class="padHeader b font16" style="color: #132c8c">Inventory</div>
                        <div class="padHeader">
                            <img v-if="showInventory" :src="require('@/assets/images/expand_more.svg')" />
                            <img v-else :src="require('@/assets/images/expand_less.svg')" />
                        </div>
                    </div>
                    <div class="flex ml2 mr2 mb2 flex-wrap justify-between gap16" v-if="showInventory">
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48 font16">
                                <router-link :to="{ name: 'InventoryReportNew' }" class="cardHead"
                                    >Inventory summary report</router-link
                                >
                                <div><img :src="require('@/assets/images/arrRight.svg')" /></div>
                            </div>
                            <div class="padSec pa2">
                                Get a quick snapshot of your inventory with the Inventory Summary Report. This concise report
                                provides a brief yet comprehensive overview of your current stock levels, helping you stay on top
                                of your inventory management effortlessly.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">All Inventory Report</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This report helps organizations maintain optimal inventory levels and prevent stock-outs.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Low inventory report</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This report helps organizations maintain optimal inventory levels and prevent stock-outs.
                            </div>
                        </div>

                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Inventory replenishment report</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This helps managers ensure that they are getting the best value for their purchases.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Inventory performance report</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This report helps organizations maintain optimal inventory levels and prevent stock-outs.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Inventory Analysis Report</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This report helps organizations maintain optimal inventory levels and prevent stock-outs.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">On-hand inventory report</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                It helps a business identify any discrepancies in inventory, identify areas where stock might need
                                to be replenished or where over-ordering is occurring, monitor product expiration, and track
                                seasonal patterns of demand.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <router-link :to="{ name: 'SalesInventoryReport' }" class="cardHead">
                                  Sales inventory report
                                </router-link>
                                <!-- <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div> -->
                            </div>
                            <div class="padSec pa2">
                                It helps a business identify any discrepancies in inventory, identify areas where stock might need
                                to be replenished or where over-ordering is occurring, monitor product expiration, and track
                                seasonal patterns of demand.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <router-link :to="{ name: 'PurchaseInventoryReport' }" class="cardHead">
                                  Purchase inventory report
                                </router-link>
                                <!-- <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div> -->
                            </div>
                            <div class="padSec pa2">
                                It helps a business identify any discrepancies in inventory, identify areas where stock might need
                                to be replenished or where over-ordering is occurring, monitor product expiration, and track
                                seasonal patterns of demand.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="reportContainer">
                    <div class="flex justify-between" @click="toggleShowProducts">
                        <div class="padHeader b font16" style="color: #132c8c">Product</div>
                        <div class="padHeader">
                            <img v-if="showProducts" :src="require('@/assets/images/expand_more.svg')" />
                            <img v-else :src="require('@/assets/images/expand_less.svg')" />

                        </div>
                    </div>
                    <div class="flex ml2 mr2 mb2 flex-wrap justify-between gap16" v-if="showProducts">
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Popular products</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                It has to do with the item name, quantity, location, date received, and date of last inventory
                                count.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Product sales by type</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                It helps a business identify any discrepancies in inventory, identify areas where stock might need
                                to be replenished.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Product sales by outlet</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This report helps organizations maintain optimal inventory levels and prevent stock-outs.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Product sales by supplier</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                Additionally, this report can be used to measure performance and profitability over time.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Product sales by user</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This helps managers ensure that they are getting the best value for their purchases.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Product sales by category</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This report helps organizations maintain optimal inventory levels and prevent stock-outs.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Product sales by customer group</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This report helps organizations maintain optimal inventory levels and prevent stock-outs.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="reportContainer">
                    <div class="flex justify-between" @click="toggleShowSales">
                        <div class="padHeader b font16" style="color: #132c8c">Sales</div>
                        <div class="padHeader">
                            <img v-if="showSales" :src="require('@/assets/images/expand_more.svg')" />
                            <img v-else :src="require('@/assets/images/expand_less.svg')" />

                        </div>
                    </div>
                    <div class="flex ml2 mr2 mb2 flex-wrap justify-between gap16" v-if="showSales">
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <router-link :to="{ name: 'SalesReportNew' }" class="cardHead"
                                    >Sales Performance Overview</router-link
                                >
                                <div><img :src="require('@/assets/images/arrRight.svg')" /></div>
                            </div>
                            <div class="padSec pa2">
                                Dive into detailed insights on daily, monthly, and annual sales, including the count and value of
                                transactions.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Staff</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">We have Quality staff.</div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Customers</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This report helps organizations maintain optimal inventory levels and prevent stock-outs.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Brand</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                Additionally, this report can be used to measure performance and profitability over time.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Supplier</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This helps managers ensure that they are getting the best value for their purchases.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Product Category</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This report helps organizations maintain optimal inventory levels and prevent stock-outs.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Tag</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This helps managers ensure that they are getting the best value for their purchases.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Register</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This report helps organizations maintain optimal inventory levels and prevent stock-outs.
                            </div>
                        </div>
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Tag</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This report helps organizations maintain optimal inventory levels and prevent stock-outs.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="reportContainer">
                    <div class="flex justify-between" @click="toggleShowExpenses">
                        <div class="padHeader b font16" style="color: #132c8c">Expenses</div>
                        <div class="padHeader">
                            <img v-if="showExpenses" :src="require('@/assets/images/expand_more.svg')" />
                            <img v-else :src="require('@/assets/images/expand_less.svg')" />

                        </div>
                    </div>
                    <div class="flex ml2 mr2 mb2 flex-wrap justify-between gap16" v-if="showExpenses">
                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <router-link :to="{ name: 'ExpenseReportNew' }" class="cardHead"
                                    >Expense Performance Overview</router-link
                                >
                                <div><img :src="require('@/assets/images/arrRight.svg')" /></div>
                            </div>
                            <div class="padSec pa2">
                                This report not only breaks down your expenses by category but also provides valuable insights
                                over specific time periods
                            </div>
                        </div>

                        <div class="w-48-l w-100 mv2">
                            <div class="flex justify-between items-center pad48">
                                <div class="cardHead">Register</div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-lock" style="color: #132c8c" />
                                </div>
                            </div>
                            <div class="padSec pa2">
                                This report helps organizations maintain optimal inventory levels and prevent stock-outs.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { ref, onMounted } from 'vue'
import AppWrapper from '@/layout/AppWrapper'
export default {
    name: 'Reports',
    components: { AppWrapper },
    setup() {
        const showInventory = ref(false)
        const showProducts = ref(false)
        const showSales = ref(false)
        const showExpenses = ref(false)

        const toggleShowInventory = () => {
            showInventory.value = !showInventory.value
            showProducts.value = false
            showSales.value = false
            showExpenses.value = false
        }
        const toggleShowProducts = () => {
            showProducts.value = !showProducts.value
            showInventory.value = false
            showSales.value = false
            showExpenses.value = false
        }
        const toggleShowSales = () => {
            showSales.value = !showSales.value
            showInventory.value = false
            showProducts.value = false
            showExpenses.value = false
        }

        const toggleShowExpenses = () => {
            showExpenses.value = !showExpenses.value
            showInventory.value = false
            showProducts.value = false
            showSales.value = false
        }

        onMounted(() => {
            window.addEventListener('click', (event) => {
                const modalIcon = document.querySelector('.clickModalIcon')

                if (
                    !(event.target.closest('.clickModalIcon') || event.target === modalIcon) &&
                    clickModalReports.value === true
                ) {
                    closeModal()
                }
            })
        })
        const clickModalReports = ref(false)
        const closeModal = () => {
            clickModalReports.value = false
        }
        return {
            clickModalReports,
            closeModal,
            showInventory,
            showProducts,
            showSales,
            toggleShowInventory,
            toggleShowProducts,
            toggleShowSales,
            showExpenses,
            toggleShowExpenses,
        }
    },
}
</script>
<style>
.padHeader {
    padding: 4px 8px;
    margin: 20px 0;
}
.pad48 {
    padding: 10px 8px;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1.5px solid rgba(81, 77, 77, 0.75);
}

.padSec {
    /* border-radius: 0px 0px 9px 8px;
    border: 1px solid #e3e8ee; */
    background: #fff;
    color: #514d4d;
    opacity: 0.75;
}
.pad16 {
    padding-bottom: 16px;
}
.font16 {
    font-size: 16px;
}
.gap16 {
    gap: 5px;
}
.cardHead {
    color: #132c8c;
    font-size: 14px;
    font-weight: 600;
}
.reportContainer {
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #e3e8ee;
    margin-bottom: 20px;
}
.comingSoon {
    background-color: #d0d9ff;
    color: #132c8c;
    width: 120px;
    margin-top: 20px;
    padding: 3px 3px 3px 15px;
    border-radius: 40px;
}
@media screen and (min-width: 60em) {
    .w-48-l {
        width: 48%;
    }
}
</style>
